import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#f3efe5",
      paper: "#F6F6F6",
    },
  },
  typography: {
    fontFamily: "Gilroy, Arial, sans-serif",
  },
});
export default theme;
